// =================== NavBarList Start here ====================
export const navBarList = [
  {
    id: 1001,
    title: "Home",
    link: "/",
  },
  {
    id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    id: 1003,
    title: "About",
    link: "/about",
  },
  {
    id: 1004,
    title: "Contact",
    link: "contact",
  },
  {
    id: 1005,
    title: "Shipping",
    link: "/shipping",
  },
];
// =================== NavBarList End here ======================
